<template lang="pug">
.navigator
  b-select.select-title(:value="activeSetting" @input="selectNavigate($event)" expanded)
    option(v-for="setting in LinkSettings" :value="setting") {{ setting.name }}
  template(v-for="setting in Settings")
    router-link(v-if="setting.type === 'link'" :to="setting.route" :class='classes(setting)') {{ setting.name }}
    hr(v-if="setting.type === 'hr'")
</template>

<script>
import { mapState } from 'vuex'
import sharesight from '@/misc/sharesight'

export default {
  computed: {
    ...mapState('authentication', ['user']),
    showManageIntegrations() {
      return sharesight.enabled
    },
    showChangePlan() {
      if (!this.user) {
        return false
      }
      return (
        this.user.paymentState === 'needed' || this.user.plan !== 'lifetime'
      )
    },
    showCCUpdate() {
      if (!this.user) {
        return false
      }
      if (this.user.plan === 'lifetime') return false
      return !!this.user.stripeId
    },
    Settings() {
      const { showCCUpdate, showChangePlan, showManageIntegrations } = this
      const ret = [
        {
          name: 'General Settings',
          type: 'link',
          route: { name: 'general_settings' }
        },
        {
          name: 'Category Settings',
          type: 'link',
          route: { name: 'category_settings' }
        },
        {
          name: 'My Goals',
          type: 'link',
          route: { name: 'goals' }
        }
      ]
      if (showManageIntegrations) {
        ret.push({
          name: 'Integrations',
          type: 'link',
          route: { name: 'integrations' }
        })
      }
      ret.push({ type: 'hr' })
      ret.push({
        name: 'Manage Account',
        type: 'link',
        route: { name: 'account_settings' }
      })
      if (showChangePlan) {
        ret.push({
          name: 'Change Plan',
          type: 'link',
          route: { name: 'billing_plan' }
        })
      }
      if (showCCUpdate) {
        ret.push({
          name: 'Payment Method',
          type: 'link',
          route: { name: 'billing_update' }
        })
      }
      return ret
    },
    LinkSettings() {
      const { Settings } = this
      return Settings.filter(({ type }) => type === 'link')
    },
    activeSetting() {
      const { Settings, $route } = this
      return Settings.find(
        setting =>
          setting.type === 'link' &&
          $route.matched.some(({ name }) => name === setting.route.name)
      )
    }
  },
  methods: {
    classes(setting) {
      const { activeSetting } = this
      if (setting === activeSetting) {
        return 'active'
      }
      return ''
    },
    selectNavigate({ route }) {
      this.$router.push(route)
    }
  }
}
</script>

<style lang="sass" scoped>
.navigator
  display: flex
  flex-direction: column
  a
    display: none
    padding: 0.25rem 0.5rem
    margin: 0.25rem 0
    color: #555
    position: relative
    &:hover
      color: black
    &.active
      font-weight: bold
  hr
    display: none
.select-title
  font-size: 1.2rem
@media screen and (min-width: 1024px)
  .select-title
    display: none
  .navigator
    a, hr
      display: block
</style>
