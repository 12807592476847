<template lang="pug">
settings-shell.user-settings
  template(#head)
    h1.title Integrations
  .box
    .one-line
      a.sharesight-logo(href="https://www.sharesight.com" target="_blank")
        img.sharesight-logo(alt="Sharesight Logo" src="@/assets/img/sharesight.svg")
    .content
      p
        | Sharesight is a share portfolio tracker that provides comprehensive
        | tax and performance reporting as well as best-in-class dividend tracking.
        | If you're already keeping track of your share portfolio(s) in Sharesight (and
        | you should be!), you can connect it to nutworth and import your data when
        | you're creating a new net worth entry.
      p(v-if="sharesightIntegration")
        | You have connected your Sharesight account already.
        span(v-if="sharesightIntegration && sharesightIntegration.expired")
          | &nbsp;However, the connection has expired and you need to reconnect.
    .integration-buttons
      .button-container
        b-button(type="is-danger" v-if="sharesightIntegration" @click="disconnectSharesight" :loading="sharesightUpdating")
          | Remove Integration
      .button-container
        b-button(type="is-primary" v-if="!sharesightIntegration || sharesightIntegration.expired" :loading="sharesightUpdating" @click="connectToSharesight")
          | Connect to Sharesight
</template>

<script>
import { mapState } from 'vuex'
import { get } from 'lodash'
import sharesight from '@/misc/sharesight'
import SettingsShell from '@/components/SettingsShell.vue'

export default {
  name: 'IntegrationsView',
  components: {
    SettingsShell
  },
  data() {
    return {
      sharesightUpdating: false
    }
  },
  computed: {
    ...mapState('authentication', ['user']),
    sharesightIntegration() {
      return get(this, 'user.integrations.sharesight', false)
    }
  },
  methods: {
    connectToSharesight() {
      sharesight.connect().then(
        () => {
          this.$buefy.toast.open('Connected to Sharesight.')
        },
        () => {}
      )
    },
    disconnectSharesight() {
      this.sharesightUpdating = true
      sharesight.disconnect().then(
        () => {
          this.$buefy.toast.open('Removed Sharesight integration.')
          this.sharesightUpdating = false
        },
        error => {
          this.$buefy.toast.open(error.message)
          this.sharesightUpdating = false
        }
      )
    }
  },
  metaInfo: {
    title: 'Integrations'
  }
}
</script>

<style lang="sass" scoped>
.one-line
  display: flex
  justify-content: space-between
  align-items: flex-end
  margin-bottom: 1.5rem
  border-bottom: 1px solid #ccc
  padding-bottom: 0.5rem
.sharesight-logo
  display: block
  width: 150px
  height: 65px
.box
  padding: 1.25rem 2rem
  width: 100%
  max-width: 550px
.integration-buttons
  display: flex
  flex-wrap: wrap
  justify-content: center
  margin: -0.25rem -0.5rem 0 -0.5rem
  .button-container
    padding: 0.25rem
    flex: 1
    min-width: 180px
    max-width: 250px
    width: 100%
  .button
    width: 100%
</style>
