import firebase from 'firebase/app'
import store from '@/store'
import dayjs from '@/dayjs'

const disconnect = firebase.functions().httpsCallable('sharesightDisconnect')
const uriLoader = firebase.functions().httpsCallable('sharesightAuthRedirect')

let redirectURIPromise = null
function sharesightInitURI() {
  if (redirectURIPromise) {
    return redirectURIPromise
  }
  redirectURIPromise = uriLoader()
    .then(({ data }) => data.uri)
    .catch(error => {
      // If an error occurred (maybe the network wasn't available?) we clear
      // the promise so that subsequent requests will retry
      redirectURIPromise = null
      throw error
    })
  return redirectURIPromise
}

function connect() {
  return new Promise((resolve, reject) => {
    let succeeded = false
    window.sharesightAuthSuccess = function() {
      console.log('Sharesight auth succeeded.')
      succeeded = true
      resolve()
    }
    sharesightInitURI()
      .then(
        uri =>
          window.open(
            uri,
            'SharesightConnect',
            'width=400,height=660,modal=yes,alwaysRaised=yes'
          ),
        reject
      )
      .then(popup => {
        if (!popup) {
          // Popup blocked, they need to click
          reject(new Error('manual-reconnect'))
          return
        }
        const interval = setInterval(() => {
          if (popup.closed) {
            // Stop polling the window state
            clearInterval(interval)
            // Prefer the window.opener method if that has happened
            if (succeeded) {
              return
            }
            // Try wait for an update to propagate back from firestore
            // if window.opener did not work (e.g IE11 issue or localhost)
            setTimeout(() => {
              const user = store.getters['authentication/user']
              if (
                user &&
                user.integrations &&
                user.integrations.sharesight &&
                !user.integrations.sharesight.expired
              ) {
                resolve()
              } else {
                reject(new Error('You closed the popup window.'))
              }
            }, 1500)
          } else {
            try {
              // See if it's on our domain
              if (document.domain === popup.document.domain) {
                // Check for the success flag
                if (popup.sharesightAuthStatus) {
                  resolve()
                  popup.close()
                  clearInterval(interval)
                }
              }
              // If it's still on the sharesight domain, we'll get a
              // security error so suppress that
            } catch {} // eslint-disable-line
          }
        }, reject)
      }, 250)
  })
}

function applicable(category) {
  return ~['equity', 'retirement', 'custom'].indexOf(category.type)
}

const sharesightGetPortfolios = firebase
  .functions()
  .httpsCallable('sharesightListPortfolios')

function withRetryLogic(fn, retry = 0) {
  return new Promise((resolve, reject) => {
    if (retry >= 10) {
      reject(new Error("Sharesight's API seems to be unavailable right now."))
      return
    }
    fn().then(({ data }) => {
      switch (data.status) {
        case 'ok':
          resolve(data)
          break
        case 'expired':
          connect()
            .catch(error => {
              if (error.message === 'manual-reconnect') {
                throw error
              } else {
                throw new Error(
                  'You need to reconnect to Sharesight to allow access.'
                )
              }
            })
            .then(() => withRetryLogic(fn, retry))
            .then(resolve)
            .catch(reject)
          break
        case 'retry':
          setTimeout(() => {
            withRetryLogic(fn, retry + 1).then(resolve, reject)
          }, 1000)
          break
        case 'error':
          reject(new Error(data.message))
          break
        default:
          reject(new Error(`Could not connect: Unknown status ${data.status}`))
      }
    }, reject)
  })
}

async function getPortfolios() {
  const { portfolios } = await withRetryLogic(sharesightGetPortfolios)
  return portfolios
}

const sharesightGetPortfolio = firebase
  .functions()
  .httpsCallable('sharesightGetPortfolio')

async function getPortfolio(portfolioId, date) {
  const { portfolio } = await withRetryLogic(() =>
    sharesightGetPortfolio({
      portfolioId,
      date: dayjs(date).format('YYYY-MM-DD')
    })
  )
  return portfolio
}

// Feature flag
const enabled = process.env.VUE_APP_SHARESIGHT_ENABLE === '1'

if (enabled) {
  // We pre-load the sharesight init URI as it can be quite slow
  // We suppress errors in case the network wasn't available
  sharesightInitURI().catch(() => {})
}

export default {
  enabled,
  applicable,
  connect,
  disconnect,
  getPortfolios,
  getPortfolio
}
