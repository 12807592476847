<template lang="pug">
section.settings-shell(:class="classes")
  settings-navigator.navigator
  .head
    slot(name='head')
  .actions.buttons
    slot(name='actions')
  .main
    slot
</template>

<script>
import SettingsNavigator from '@/components/SettingsNavigator'

export default {
  components: {
    SettingsNavigator
  },
  computed: {
    nActions() {
      const { actions } = this.$slots
      if (actions) {
        return actions.length
      }
      return 0
    },
    classes() {
      const { nActions } = this
      const classes = {}
      if (nActions) {
        classes['has-actions'] = true
        classes[`has-${nActions}-actions`] = true
        return classes
      }
      return ''
    }
  }
}
</script>

<style lang="sass" scoped>
.settings-shell
  display: grid
  padding: 1rem .75rem 2rem
  grid-template-areas: "navigator" "actions" "main"
.navigator
  grid-area: navigator
.head
  grid-area: head
  display: flex
  align-items: center
  display: none
.main
  grid-area: main
  min-width: 0
.actions
  grid-area: actions
  display: flex
  align-items: center
  justify-content: center
  margin: 0.5rem 0 1rem
  ::v-deep button
    flex-grow: 1
    margin: 0
    min-width: 140px
    max-width: 200px

@media screen and (min-width: 0px)
  .settings-shell.has-1-actions
    grid-template-areas: "navigator actions" "main main"
    grid-template-columns: 1fr max-content
    .actions
      margin: 0 0 0 0.5rem
      ::v-deep button
        font-size: 1.2rem
    .main
      margin-top: 1rem

@media screen and (min-width: 600px)
  .settings-shell.has-2-actions
    grid-template-areas: "navigator actions" "main main"
    grid-template-columns: 1fr max-content
    .actions
      margin: 0 0 0 0.5rem
      ::v-deep button
        font-size: 1.2rem
    .main
      margin-top: 1rem

@media screen and (min-width: 1024px)
  .settings-shell, .settings-shell.has-actions
    grid-template-areas: "blank head actions" "navigator main main"
    grid-template-columns: 200px 1fr
    grid-template-rows: 5rem 1fr
    max-width: 1344px
    margin: 0 auto
    padding: 1rem 1.5rem 2rem
    .main
      margin-top: 0
  .head
    display: flex
  .navigator
    padding-right: 1.5rem
  .actions
    margin-bottom: 0
    ::v-deep button
      margin-bottom: 0
</style>
